<template>
  <div>
    <v-container class="container--fluid">
      <v-row dense>
        <v-col cols="12">
          <v-card>
            <v-col
              cols="12"
              class="pl-0 pr-0 pb-1"
            >
              <v-row>
                <v-spacer />

                <v-col
                  cols="3"
                  sm="2"
                  md="2"
                  class="pr-3"
                >
                  <v-select
                    v-model="santral"
                    :items="santralDataList"
                    label="Santral"
                  />
                </v-col>

                <v-col
                  cols="3"
                  sm="2"
                  md="2"
                  class="pr-3"
                >
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="startDate"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{on, attrs}">
                      <v-text-field
                        v-model="startDate"
                        label="Picker in menu"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="startDate"
                      no-title
                      scrollable
                    >
                      <v-spacer />
                      <v-btn
                        text
                        color="primary"
                        @click="menu = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(startDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col
                  cols="3"
                  sm="1"
                  md="1"
                  class="pr-3"
                >
                  <v-btn
                    color="primary"
                    @click="getDailyMinuteEnergy"
                  >
                    Filtrele
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              ref="renderComponent"
              cols="12"
            >
              <template v-if="livehourdata">
             
                <echart
                  id="santral-sensor-vals"
                  :key="componentKey"
                  class-name="widget-content"
                  height="450px"
                  width="100%"
                  :chart-data="livehourdata"
                />
                <v-divider class="" />
              </template>
            </v-col>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Echart from '@/views/components/Echart/Chart.vue';
import * as SensorBox from '@/api/SensorBox/ChartOption';
import moment from 'moment';

export default {
  name: 'Dashboard',
  components: {
    Echart,
  },
  data: () => ({
    santral: '',
    riseze: 1,
    componentKey: 1,
    startDate: moment().format('YYYY-MM-DD'),
    dailydata: {},
    monthlydata: {},
    yearlydata: {},
    year: moment().format('YYYY'),
    menu: false,
    modal: false,
    menu2: false,
    alarmDialog: false,
    livehourdata: {},
    inverterList: {},
    sensorInfo: {},
  }),
  computed: {
    santralDataList() {
      const { santralDataList } = this.$store.getters;

      if (Object.keys(santralDataList).length > 0) {
        return santralDataList;
      }
    },
  },
  watch: {
    santral() {
      this.getDailyMinuteEnergy();
    },
     $route: function (from, to) {
       console.log("snsorrrrrrrrrrrr")
      this.riseze++;
        console.log(this.riseze)
    },
  },
  created() {},
  mounted() {
    const myVar1 = setInterval(myTimer1, 2000);

    const self = this;

    function myTimer1() {
      const { santral } = self.$store.getters.selectedSantralModel;
      const { sensorInfo } = self.$store.getters;

      if (santral !== undefined && Object.keys(sensorInfo).length > 0) {
        self.santral = santral;
        self.sensorInfo = sensorInfo;
        clearInterval(myVar1);
      }
    }
  },
  methods: {
    getDailyMinuteEnergy() {
      this.finishDate = moment(this.startDate).format('YYYY-MM-DD');
      const text = '';

      const params = {
        condiniton: {
          startDate: this.startDate,
          finishDate: this.finishDate,
          santral: this.santral,
          sensor: this.sensorInfo[this.santral],
        },
      };

      const tempData = SensorBox.default.getSensorBoXDailyChartOpt(
        params,
        this.sensorInfo[this.santral],
        text,
      );
      tempData.then((res) => {
        this.livehourdata = res;
        this.componentKey++;
      });
    },

    forceRerender() {
      this.renderComponent = false;
      this.$nextTick().then(() => {
        this.renderComponent = true;
      });
    },
  },
};
</script>
